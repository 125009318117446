import dayjs from 'dayjs';

import { BillingIntervals, Plan, Price } from '../types';

export const getFormattedStripePrice = (price: number) => {
    return price / 100;
};

export const getProductPrice = (
    billingInterval: BillingIntervals,
    prices: Price[],
    isYearlyPriceAsMonthly?: boolean
) => {
    const productPrice =
        prices.find((price) => price?.billingInterval.toLowerCase() === billingInterval)?.unitAmount ?? 0;
    const formattedPrice = getFormattedStripePrice(productPrice);

    if (billingInterval === BillingIntervals.Annualy && isYearlyPriceAsMonthly) {
        return Math.floor(formattedPrice / 12);
    }

    return formattedPrice;
};

export const getPriceWithCurrency = (price: number, currency?: string) => {
    return `${price} ${currency ?? 'kr'}`;
};

export const getProductInfo = (prices: Price[], campaignName: string, trialEndDate: string | null) => {
    return prices?.reduce(
        (acc, price) => {
            if (price.billingInterval.toLowerCase() === BillingIntervals.Annualy) {
                acc.annualPrice += getPriceWithCurrency(getFormattedStripePrice(price.unitAmount));
                acc.annualLookupKey += price.key;
            } else {
                acc.monthlyPrice += getPriceWithCurrency(getFormattedStripePrice(price.unitAmount));
                acc.monthlyLookupKey += price.key;
            }

            return acc;
        },
        {
            campaignName: campaignName,
            annualPrice: '',
            monthlyPrice: '',
            monthlyLookupKey: '',
            annualLookupKey: '',
            trialEndDate: trialEndDate ? dayjs(trialEndDate).format('YYYY-MM-DD') : '',
        }
    );
};

export const getFormattedTrialEndDate = (trialEndDate: Date | string | null) => {
    if (!trialEndDate) return;
    const trialEndDateMinusOneDay = dayjs(trialEndDate).subtract(1, 'day'); // NOTE: Subtracting one day to get the last day of the trial period
    return trialEndDateMinusOneDay.locale('sv').format('D MMMM');
};

export const getPlanData = ({
    trialEndDate,
    plans,
    prices,
}: {
    prices: Price[];
    plans: Plan[];
    trialEndDate?: string;
}) => {
    const fallbackOfferText = trialEndDate ? `Prova gratis fram till ${trialEndDate}` : undefined;

    const offerTextAnnual =
        plans.find((plan) => plan.billingInterval === BillingIntervals.Annualy)?.offerText || fallbackOfferText;
    const offerTextMonthly =
        plans.find((plan) => plan.billingInterval === BillingIntervals.Monthly)?.offerText || fallbackOfferText;
    const monthlyPrice = getProductPrice(BillingIntervals.Monthly, prices);
    const annualPrice = getProductPrice(BillingIntervals.Annualy, prices);

    return { offerTextAnnual, offerTextMonthly, monthlyPrice, annualPrice };
};
