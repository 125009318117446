import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCampaignInfo, setUserAbTests, stripeCheckoutSession } from '@jaramba-frontend/core/api';
import { Loading } from '@jaramba-frontend/core/components';
import { UTM_COOKIE_NAME } from '@jaramba-frontend/core/constants';
import { useMarketingData } from '@jaramba-frontend/core/hooks';
import { AnalyticsService, CookieService } from '@jaramba-frontend/core/services';
import { AbTest, BillingIntervals } from '@jaramba-frontend/core/types';
import { getProductInfo } from '@jaramba-frontend/core/utils';

import { createUser } from '../../api';
import { CreateUserForm, ProductInfo } from '../../components';
import { routes } from '../../constants';
import { useCheckoutContext } from '../../hooks';
import type { CheckoutErrors, ProductData } from '../../types';
import styles from './styles.module.scss';

const CreateUserAccount = () => {
    const [searchParams] = useSearchParams();
    const { productInfo, billingInterval, setToken, setUid, setEmail, setBillingInterval, setProductInfo } =
        useCheckoutContext();
    const { getStoredMarketingData } = useMarketingData();

    const [isLoadingCampaign, setLoadingCampaign] = useState<boolean>(false);
    const [isFormSubmitting, setFormSubmitting] = useState<boolean>(false);
    const [responseErrors, setResponseErrors] = useState<CheckoutErrors>(null);

    const marketingData = getStoredMarketingData();

    const fetchAndSetCampaignInfo = async () => {
        try {
            const campaignParam = searchParams.get('campaignName') || undefined;
            const { prices, campaign } = await getCampaignInfo(campaignParam);

            if (prices && campaign) {
                const productInfoRes: ProductData = getProductInfo(prices, campaign.name, campaign.debitDate);

                setProductInfo({
                    ...productInfoRes,
                    offerText: campaign.plans.find((plan) => plan.billingInterval === billingInterval)?.offerText,
                });
            }
        } catch (err) {
            console.error(err);
            throw new Error('Failed to fetch campaign or product data');
        }
    };

    useEffect(() => {
        (async () => {
            setBillingInterval((searchParams.get('billingInterval') as BillingIntervals) ?? BillingIntervals.Monthly);
            setLoadingCampaign(true);

            await fetchAndSetCampaignInfo();

            setLoadingCampaign(false);
        })();
    }, []);

    const onEmailChange = () => {
        setResponseErrors(null);
    };

    const handleCreateAccount = async (email: string, password: string, hasInputErrors: boolean) => {
        if (!responseErrors && !hasInputErrors) {
            setFormSubmitting(true);

            try {
                AnalyticsService.tagEvent({
                    event: 'sign_up_form_submission',
                    category: 'checkout',
                    action: 'USER_SIGNING_UP',
                    label: 'Clicks on "Sign Up" button after form is filled.',
                });

                const res = await createUser(email, password, marketingData);

                if (res?.email && res?.token && res?.uid) {
                    setEmail(res.email);
                    setToken(res.token);
                    setUid(res.uid);
                    CookieService.remove(UTM_COOKIE_NAME);

                    AnalyticsService.tagEvents([
                        {
                            event: 'sign_up_success',
                            category: 'checkout',
                            action: 'USER_SIGNED_UP',
                            label: 'User signed up successfully.',
                        },
                        {
                            event: 'redirect_to_stripe_checkout',
                            category: 'checkout',
                            action: 'REDIRECT_TO_STRIPE_CHECKOUT_AFTER_SIGNUP',
                            label: 'Redirecting user to Stripe checkout.',
                        },
                    ]);

                    const abTests: AbTest[] = [];

                    if (abTests.length > 0) {
                        await setUserAbTests(abTests, res.token).catch(() => null);
                    }

                    await handleOpenStripeCheckout(res.token);
                }
            } catch (err) {
                if (err instanceof AxiosError) {
                    setResponseErrors(err?.response?.data?.Errors);
                } else {
                    throw new Error('User account creation failed. Unexpected error');
                }

                setFormSubmitting(false);
            }
        }
    };

    const handleOpenStripeCheckout = async (token: string) => {
        if (productInfo?.campaignName) {
            try {
                const redirectUrl = await stripeCheckoutSession({
                    token,
                    successUrl: `${window.location.origin}${routes.SUCCESS}?paymentSucceed=1`,
                    cancelUrl: `${window.location.origin}${routes.PAYMENT}${window.location.search}`,
                    priceLookupKey:
                        billingInterval === BillingIntervals.Annualy
                            ? productInfo.annualLookupKey
                            : productInfo.monthlyLookupKey,
                    campaignId: productInfo.campaignName,
                });

                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    throw new Error('Unable to open Stripe Checkout');
                }
            } catch (error) {
                console.error(error);
                throw new Error('Stripe Checkout session request error');
            }
        }
    };

    return (
        <div className={styles.pageContent}>
            {productInfo ? (
                <ProductInfo
                    campaignName={productInfo?.campaignName ?? ''}
                    monthlyPrice={productInfo?.monthlyPrice ?? ''}
                    annualPrice={productInfo?.annualPrice ?? ''}
                    trialEndDate={productInfo?.trialEndDate}
                    offerText={productInfo?.offerText}
                />
            ) : (
                <Loading />
            )}

            <CreateUserForm
                isLoadingCampaign={isLoadingCampaign}
                isFormSubmitting={isFormSubmitting}
                responseErrors={responseErrors}
                onEmailChange={onEmailChange}
                onSubmit={handleCreateAccount}
            />
        </div>
    );
};

export default CreateUserAccount;
